import React, { Component } from 'react';
// import './styles/foundation.min.css';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Routes from './routes';
// import NavBar from './components/NavBar';
// import MainFooter from './components/PageFooter';
// import MobileHeader from './components/MobileHeader/MobileHeader';

class App extends Component {
    constructor(){
        super();
        this.state={
        appName: "ERP | Britannia Beverages",
        mobileAppName: "ERP | Britannia Beverages",
        home: false
        }
    }

    // let loggedData = JSON.parse(sessionStorage.getItem("userData"));
    // alert(loggedData.userData.name);

    render() {
        return (
          <Router>
            <Routes/>
          </Router>
        );
    }
}
export default App;