export function PostData(type, userData, pageType) {
    let PhpServer = 'https://britanniabeveragesie.com/bbcrud/';
    return new Promise(
        (resolve, reject) =>{
            fetch(PhpServer+pageType+'.php?t='+type,{
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }, body:JSON.stringify(userData)
            })
                .then((response) => response.json()
                .then((res) => { resolve(res); })
            )
                .catch((error) => { reject(error); });
                // .catch((error) => { alert(error); });
        }
    );   
}