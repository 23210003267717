import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
// import '../styles/PropertyManagers.css';

function ForceLogout() {
	const [isRedirect, setIsRedirect] = useState(false);
	if(isRedirect === true){
		return <Navigate to='/' />
	  }
	return (
		<div style={{marginTop:"40px", textAlign:"center"}}>
			<button style={{backgroundColor:"#44d62c", color:"#fff", width:"200px", height:"50px", cursor:"pointer"}} className="banner-btn" onClick={() => {
                sessionStorage.setItem("userData",'');
                sessionStorage.clear();
                setIsRedirect(true);
            }}>Force Logout</button>
		</div>
	)
}
  
export default ForceLogout;
