import React, { Component } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { PostData } from '../../datalink/PostData';
// import { Helmet, HelmetProvider } from 'react-helmet-async';
// import { renderToString } from 'react-dom/server';
import '../LoginPage.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from '../NavBar';
import PageFooter from '../PageFooter';
import '../../App.css';

// toast.configure();
class LoginPage extends Component {
    componentDidMount(){
        document.body.style.backgroundColor = "#01203f";
        window.scrollTo(0, 0);
    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.adminlogin();
        }
    }

    constructor(props){
        super(props);
        this.state = {
            userName: '',
            adminPassword: '',
            demoType: 'IN',
            redirectToReferrer: false
        };

        this.adminlogin = this.adminlogin.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    adminlogin() {
        // <ToastContainer />
        toast.dismiss();
        this.setState({ isLoading: true });
        if(this.state.userName && this.state.adminPassword){
            PostData('login',this.state,'generic').then((result) => {
                // Should also be able to catch Timeout
                let responseJson = result;
                if(responseJson.serverRes){
                    let receivedServerResponse = result.serverRes;

                    if(receivedServerResponse.error === "T"){ // Check if "error" is true
                       
                        this.setState({
                            isLoading: false
                        });
                        toast.error(receivedServerResponse.errMessage, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined
                        });
                    } else { // set session and redirect to dashboard
                        sessionStorage.setItem('userData',JSON.stringify(receivedServerResponse));
                        this.setState({redirectToReferrer: true});
                        // this.setState({
                        //     isLoading: false
                        // });
                    }
                } else {
                    
                    this.setState({
                        isLoading: false
                    });
                    toast.error('Error accessing the database at this time, please try again', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined
                    });
                }
            });
        } else {
            this.setState({
                isLoading: false
            });
            toast.error('You must enter your username and password', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }

    onChange(e){
        this.setState({[e.target.name]:e.target.value});
    }

    render() {
        const pageType = "generic";
        const metaBlock = (
            <>
            <title>Login - ERP | Britannia Beverages</title>
            <meta name="description" content="Login - ERP | Britannia Beverages" />
            </>
        );

        if (this.state.redirectToReferrer) {
            return (<Navigate to={'/dashboard'}/>)
            // return (<Navigate to={'/forcelogout'}/>) // temporary
        }
        if(sessionStorage.getItem('userData')){
            return (<Navigate to={'/dashboard'}/>)
            // return (<Navigate to={'/forcelogout'}/>) // temporary
        }

        return (
            <>
            {metaBlock}
            <NavBar pageType={pageType}/>
            <ToastContainer />
            <section className="genericSection">
            <div className="contentsBlock" style={{minHeight:"60vh"}}>
                <div className="bMEOtR">
                    <div className="ffYYIb col imgfiller">
                        <img src="img/erplogo.png" className="about-thumb" alt="ERP System"/>
                    </div>
                    <div className="Col__ColWrapper-ryzggq-0 ffYYIb col about-content-wrap">
                        <div className="EQkxe section-title contentMinusFiller">
                            <h2><span>User</span> Login</h2>
                            <div className="wrap-input100" data-validate="Username is required">
                                <input className="input100" type="text" ref={(el) => (this.userName = el)} name="userName" placeholder="Username or Email Address" onChange={this.onChange} />
                            </div>
                            <div>
                            </div>
                            <div className="wrap-input100" data-validate="Password is required">
                                <input className="input100" type="password" ref={(el) => (this.adminPassword = el)} name="adminPassword" placeholder="Password" onChange={this.onChange} onKeyPress={this._handleKeyPress} />
                            </div>
                            <div className="container-login100-form-btn">
                                <button className="login100-form-btn buttonMain" onClick={this.adminlogin} disabled={this.state.isLoading}>{this.state.isLoading ? <><i className="fas fa-spinner fa-spin"></i>&nbsp; Please Wait...</> : <><i className="fas fa-sign-in-alt"></i> &nbsp; Login</>}</button>
                            </div>
                            <div className="stickyNote light-shadow" style={{marginTop:"10px"}}>
                                <div style={{float:"left", fontSize:"2em"}}><i className="fas fa-info-circle"></i></div>
                                <small>
                                    This login form is for users of this system with existing account, if you are a Customer, Supplier or Service Provider of Britannia Beverages but don't have an account yet, <Link to='/contact-us'>Contact Us</Link> to request for an access.
                                    <br/><br/>
                                    Forgot Password? <Link to='/reset-password'>Reset Here</Link>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
            <PageFooter pageType={pageType}/>
        </>
        );
    }
}
export default LoginPage;