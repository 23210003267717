import React, { Component } from 'react';
import { Navigate, Link } from 'react-router-dom';
import '../../styles/SystemStyles.css';
import { PostData } from '../../datalink/PostData';
// import UserFeed from "../../UserFeed";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from '../NavBar';
import SysHeaderbar from '../SysHeaderbar';
import PageFooter from '../PageFooter';
import PageBanner from '../PageBanner';
import { confirmAlert } from 'react-confirm-alert';

function bbIEStamp() {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
              <div className="dialogBox">
                <p style={{lineHeight:"1em", textAlign:"center"}}>
                    <small>Right-click and save the image to your computer. This image is transparent and can be put on top of another image or text.</small><br/>
                    <img src="/img/bbie-stamp1.png" alt=""/>
                </p>
                <div className="buttonDiv">
                    <button className="banner-btn two" onClick={() => {onClose();}}>Close</button>
                </div>
              </div>
            );
        }
    });
}
function bbUKStamp() {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
              <div className="dialogBox">
                <p style={{lineHeight:"1em", textAlign:"center"}}>
                    <small>Right-click and save the image to your computer. This image is transparent and can be put on top of another image or text.</small><br/>
                    <img src="/img/bbuk-stamp1.png" alt=""/>
                </p>
                <div className="buttonDiv">
                    <button className="banner-btn two" onClick={() => {onClose();}}>Close</button>
                </div>
              </div>
            );
        }
    });
}
// toast.configure();
class DownloadableForms extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data:[],
            redirectToReferrer: false
        };
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#fff";
        if(sessionStorage.getItem("userData")){
            if(JSON.parse(sessionStorage.getItem("userData")).userType==="OR"){
                this.setState({redirectToReferrer: true});
            }
        }else{
            this.setState({redirectToReferrer: true});
        }
        window.scrollTo(0, 0);
    }

    render() {
        if (this.state.redirectToReferrer) {
            if(JSON.parse(sessionStorage.getItem("userData")).userType==="OR") {
                return (<Navigate to={'/dashboard'}/>);
            }
            return (<Navigate to={'/'}/>);
        }

        if (sessionStorage.getItem("userData")==null || sessionStorage.getItem("userData")=="") {
            return (<Navigate to={'/'}/>);
        }
        const pageType = "system";
        let sessionvars="";
        const userName = JSON.parse(sessionStorage.getItem("userData")).userName;
        
        sessionvars = {
            isDemo:false,
            loggedUserCid:JSON.parse(sessionStorage.getItem("userData")).cid,
            loggedUserEmail:JSON.parse(sessionStorage.getItem("userData")).emailAddress,
            loggedUserName:JSON.parse(sessionStorage.getItem("userData")).userName,
            loggedName:JSON.parse(sessionStorage.getItem("userData")).fullName,
            loggedUserType:JSON.parse(sessionStorage.getItem("userData")).userType,
            loggedUserDesignation:JSON.parse(sessionStorage.getItem("userData")).userDesignation
        }

        return (
            <>
            <title>Downloadable Forms &amp; Templates - ERP | Britannia Beverages</title>
            <meta name="description" content="Downloadable Forms &amp; Templates - ERP | Britannia Beverages" />
            <NavBar pageType={pageType}/>
            <div className="main-content">
                <SysHeaderbar sessionvars={sessionvars}/>
                <main>
                    <h2 className="dash-title">Downloadable Forms &amp; Templates</h2>
                    <PopulateThisPage sessionvars={sessionvars} />
                </main>
                <PageFooter pageType={pageType}/>
            </div>
            </>
        );
    }
}

class PopulateThisPage extends Component {
    render() {
        return (
            <>
            <ToastContainer />
            <div className="dash-cards">
                <div className="card-single light-shadow">
                    <div className="card-body">
                        <div className="fullDiv">
                            <img className="inset-customerlogo" src="/img/form-icon1.jpg" alt=""/>
                            <h5>Annex VII</h5>
                            <h6>Should be prepared prior to loading of container</h6>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="">PDF</Link>
                    </div>
                </div>
                <div className="card-single light-shadow">
                    <div className="card-body">
                        <div className="fullDiv">
                            <img className="inset-customerlogo" src="/img/form-icon2.jpg" alt=""/>
                            <h5>Annex III</h5>
                            <h6>Should be prepared prior to loading of container</h6>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="">PDF</Link>
                    </div>
                </div>
                <div className="card-single light-shadow">
                    <div className="card-body">
                        <div className="fullDiv">
                            <img className="inset-customerlogo" src="/img/customers/maaden-logo1.jpg" alt=""/>
                            <h5>Label for Maaden Shipment</h5>
                            <h6>Should be prepared prior to loading of container</h6>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="">PDF</Link> | <Link to="">MS Word</Link>
                    </div>
                </div>
                <div className="card-single light-shadow">
                    <div className="card-body">
                        <div className="fullDiv">
                            <img className="inset-customerlogo" src="/img/customers/uacj-logo1.png" alt=""/>
                            <h5>Label for UACJ Shipment</h5>
                            <h6>Should be prepared prior to loading of container</h6>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="">PDF</Link> | <Link to="">MS Word</Link>
                    </div>
                </div>
                <div className="card-single light-shadow">
                    <div className="card-body">
                        <div className="fullDiv">
                            {/* <img className="inset-customerlogo" src="/img/customers/uacj-logo1.png" alt=""/> */}
                            <h5>Stamp</h5>
                            <h6>For use on some shipment paperworks and reports</h6>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="" onClick={bbIEStamp}>BBIE</Link> | <Link to="" onClick={bbUKStamp}>BBUK</Link>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default DownloadableForms;