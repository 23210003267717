import React from 'react';
import { Navigate } from 'react-router-dom';
import '../../styles/SystemStyles.css';
import { PostData } from '../../datalink/PostData';
// import UserFeed from "../../UserFeed";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from '../NavBar';
import SysHeaderbar from '../SysHeaderbar';
import PageFooter from '../PageFooter';
// import PageBanner from '../PageBanner';
// import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
// import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

function TabContainer(props) {
    return (
      <Typography component="div" style={{ padding: 8 * 3 }}>
        {props.children}
      </Typography>
    );
}
  
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
});

const resultStyleRegular = {
    fontWeight:"500", color:"#000033", fontSize:"1.1em", border:"1px dotted #000", padding:"1px 10px"
};
const resultStyleMemo = {
    fontWeight:"500", color:"#000033", fontSize:"1.1em", border:"1px dotted #000", padding:"1px 10px"
};
const resultStyleDate = {
    fontWeight:"500", color:"#000033", fontSize:"0.9em", border:"1px dotted #000", padding:"1px 10px", fontStyle:"italic"
};

function ResultGbpUsd(props) {
    if (props.resultMessageText === "success") {
        return (
            <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                <div className="card-body">
                    <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                        <h5 style={{color:"#2e7d32"}}>In Quickbooks Online</h5>
                        Initiate a transfer transaction and enter the following values and details
                        <br/><br/>
                        Transfer Funds From, select:<br/>
                        <span style={resultStyleRegular}>GBP Clydesdale - GBP</span><br/><br/>
                        Transfer Funds To, select:<br/>
                        <span style={resultStyleRegular}>USD Clydesdale - USD</span><br/><br/>
                        Currency, select:<br/>
                        <span style={resultStyleRegular}>USD</span><br/><br/>
                        Date:<br/>
                        <span style={resultStyleDate}>Enter / select the actual transaction date</span><br/><br/>
                        FX Rate (USD / GBP), enter:<br/>
                        <span style={resultStyleRegular}>{props.gbpUsdFxRate}</span><br/><br/>
                        Transfer Amount, enter this value:<br/>
                        <span style={resultStyleRegular}>{props.gbpUsdAmount}</span><br/><br/>
                        Memo, enter the following text:<br/>
                        <span style={resultStyleMemo}>{props.gbpUsdMemoText}</span><br/><br/>
                    </div>
                </div>
            </div>
        );
    }else if (props.resultMessageText === "processing") {
        if (props.isThereaServerResponse.trim() === "yes" || props.isThereaServerResponse.trim() === "initial") {        
            return (
                <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                    <div className="card-body">
                        <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                            <h5 style={{color:"#2e7d32"}}>Processing Result</h5>
                            Processing...
                        </div>
                    </div>
                </div>
            );
        }else{
            return (
                <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                    <div className="card-body">
                        <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                            <h5 style={{color:"#2e7d32"}}>Processing Result</h5>
                            Error processing this request...
                        </div>
                    </div>
                </div>
            );
        }
    }else{
        if (props.resultMessageText.trim() !== "") {
            return (
                <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                    <div className="card-body">
                        <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                            <h5 style={{color:"#2e7d32"}}>Processing Result</h5>
                            {props.resultMessageText}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

function ResultUsdGbp(props) {
    if (props.resultMessageText === "success") {
        return (
            <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                <div className="card-body">
                    <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                        <h5 style={{color:"#2e7d32"}}>In Quickbooks Online</h5>
                        Initiate a transfer transaction and enter the following values and details
                        <br/><br/>
                        Transfer Funds From, select:<br/>
                        <span style={resultStyleRegular}>USD Clydesdale - USD</span><br/><br/>
                        Transfer Funds To, select:<br/>
                        <span style={resultStyleRegular}>GBP Clydesdale - GBP</span><br/><br/>
                        Currency, select:<br/>
                        <span style={resultStyleRegular}>USD</span><br/><br/>
                        Date:<br/>
                        <span style={resultStyleDate}>Enter / select the actual transaction date</span><br/><br/>
                        FX Rate (USD / GBP), enter:<br/>
                        <span style={resultStyleRegular}>{props.usdGbpFxRate}</span><br/><br/>
                        Transfer Amount, enter this value:<br/>
                        <span style={resultStyleRegular}>{props.usdGbpAmount}</span><br/><br/>
                        Memo, enter the following text:<br/>
                        <span style={resultStyleMemo}>{props.usdGbpMemoText}</span><br/><br/>
                    </div>
                </div>
            </div>
        );
    }else if (props.resultMessageText === "processing") {
        if (props.isThereaServerResponse.trim() === "yes" || props.isThereaServerResponse.trim() === "initial") {        
            return (
                <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                    <div className="card-body">
                        <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                            <h5 style={{color:"#2e7d32"}}>Processing Result</h5>
                            Processing...
                        </div>
                    </div>
                </div>
            );
        }else{
            return (
                <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                    <div className="card-body">
                        <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                            <h5 style={{color:"#2e7d32"}}>Processing Result</h5>
                            Error processing this request...
                        </div>
                    </div>
                </div>
            );
        }
    }else{
        if (props.resultMessageText.trim() !== "") {
            return (
                <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                    <div className="card-body">
                        <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                            <h5 style={{color:"#2e7d32"}}>Processing Result</h5>
                            {props.resultMessageText}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

function ResultGbpEur(props) {
    if (props.resultMessageText === "success") {
        return (
            <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                <div className="card-body">
                    <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                        <h5 style={{color:"#2e7d32"}}>In Quickbooks Online</h5>
                        Initiate a transfer transaction and enter the following values and details
                        <br/><br/>
                        Transfer Funds From, select:<br/>
                        <span style={resultStyleRegular}>GBP Clydesdale - GBP</span><br/><br/>
                        Transfer Funds To, select:<br/>
                        <span style={resultStyleRegular}>EUR Clydesdale - EUR</span><br/><br/>
                        Currency, select:<br/>
                        <span style={resultStyleRegular}>EUR</span><br/><br/>
                        Date:<br/>
                        <span style={resultStyleDate}>Enter / select the actual transaction date</span><br/><br/>
                        FX Rate (EUR / GBP), enter:<br/>
                        <span style={resultStyleRegular}>{props.gbpEurFxRate}</span><br/><br/>
                        Transfer Amount, enter this value:<br/>
                        <span style={resultStyleRegular}>{props.gbpEurAmount}</span><br/><br/>
                        Memo, enter the following text:<br/>
                        <span style={resultStyleMemo}>{props.gbpEurMemoText}</span><br/><br/>
                    </div>
                </div>
            </div>
        );
    }else if (props.resultMessageText === "processing") {
        if (props.isThereaServerResponse.trim() === "yes" || props.isThereaServerResponse.trim() === "initial") {        
            return (
                <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                    <div className="card-body">
                        <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                            <h5 style={{color:"#2e7d32"}}>Processing Result</h5>
                            Processing...
                        </div>
                    </div>
                </div>
            );
        }else{
            return (
                <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                    <div className="card-body">
                        <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                            <h5 style={{color:"#2e7d32"}}>Processing Result</h5>
                            Error processing this request...
                        </div>
                    </div>
                </div>
            );
        }
    }else{
        if (props.resultMessageText.trim() !== "") {
            return (
                <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                    <div className="card-body">
                        <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                            <h5 style={{color:"#2e7d32"}}>Processing Result</h5>
                            {props.resultMessageText}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

function ResultEurGbp(props) { 
    if (props.resultMessageText === "success") {
        return (
            <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                <div className="card-body">
                    <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                        <h5 style={{color:"#2e7d32"}}>In Quickbooks Online</h5>
                        Initiate a transfer transaction and enter the following values and details
                        <br/><br/>
                        Transfer Funds From, select:<br/>
                        <span style={resultStyleRegular}>EUR Clydesdale - EUR</span><br/><br/>
                        Transfer Funds To, select:<br/>
                        <span style={resultStyleRegular}>GBP Clydesdale - GBP</span><br/><br/>
                        Currency, select:<br/>
                        <span style={resultStyleRegular}>EUR</span><br/><br/>
                        Date:<br/>
                        <span style={resultStyleDate}>Enter / select the actual transaction date</span><br/><br/>
                        FX Rate (EUR / GBP), enter:<br/>
                        <span style={resultStyleRegular}>{props.eurGbpFxRate}</span><br/><br/>
                        Transfer Amount, enter this value:<br/>
                        <span style={resultStyleRegular}>{props.eurGbpAmount}</span><br/><br/>
                        Memo, enter the following text:<br/>
                        <span style={resultStyleMemo}>{props.eurGbpMemoText}</span><br/><br/>
                    </div>
                </div>
            </div>
        );
    }else if (props.resultMessageText === "processing") {
        if (props.isThereaServerResponse.trim() === "yes" || props.isThereaServerResponse.trim() === "initial") {        
            return (
                <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                    <div className="card-body">
                        <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                            <h5 style={{color:"#2e7d32"}}>Processing Result</h5>
                            Processing...
                        </div>
                    </div>
                </div>
            );
        }else{
            return (
                <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                    <div className="card-body">
                        <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                            <h5 style={{color:"#2e7d32"}}>Processing Result</h5>
                            Error processing this request...
                        </div>
                    </div>
                </div>
            );
        }
    }else{
        if (props.resultMessageText.trim() !== "") {
            return (
                <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                    <div className="card-body">
                        <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                            <h5 style={{color:"#2e7d32"}}>Processing Result</h5>
                            {props.resultMessageText}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

function ResultUsdEur(props) {
    if (props.resultMessageText === "success") {
        return (
            <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                <div className="card-body">
                    <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                        <h5 style={{color:"#2e7d32"}}>In Quickbooks Online</h5><br/>
                        <h3>1st Transfer</h3><hr/>
                        Initiate the <strong>first transfer transaction</strong> and enter the following values and details
                        <br/><br/>
                        Transfer Funds From, select:<br/>
                        <span style={resultStyleRegular}>USD Clydesdale - USD</span><br/><br/>
                        Transfer Funds To, select:<br/>
                        <span style={resultStyleRegular}>GBP Interim Clearing A/C</span><br/><br/>
                        Currency, select:<br/>
                        <span style={resultStyleRegular}>USD</span><br/><br/>
                        Date:<br/>
                        <span style={resultStyleDate}>Enter / select the actual transaction date</span><br/><br/>
                        FX Rate (USD / GBP), enter:<br/>
                        <span style={resultStyleRegular}>{props.usdEurFxRateUsd}</span><br/><br/>
                        Transfer Amount, enter this value:<br/>
                        <span style={resultStyleRegular}>{props.usdEurAmountUsd}</span><br/><br/>
                        Memo, enter the following text:<br/>
                        <span style={resultStyleMemo}>{props.usdEurMemoTextUsd}</span><br/><br/>

                        <br/>
                        <h3>2nd Transfer</h3><hr/>
                        Initiate the <strong>second transfer transaction</strong> and enter the following values and details
                        <br/><br/>
                        Transfer Funds From, select:<br/>
                        <span style={resultStyleRegular}>GBP Interim Clearing A/C</span><br/><br/>
                        Transfer Funds To, select:<br/>
                        <span style={resultStyleRegular}>EUR Clydesdale - EUR</span><br/><br/>
                        Currency, select:<br/>
                        <span style={resultStyleRegular}>EUR</span><br/><br/>
                        Date:<br/>
                        <span style={resultStyleDate}>Enter / select the actual transaction date</span><br/><br/>
                        FX Rate (EUR / GBP), enter:<br/>
                        <span style={resultStyleRegular}>{props.usdEurFxRateEur}</span><br/><br/>
                        Transfer Amount, enter this value:<br/>
                        <span style={resultStyleRegular}>{props.usdEurAmountEur}</span><br/><br/>
                        Memo, enter the following text:<br/>
                       <span style={resultStyleRegular}>{props.usdEurMemoTextEur}</span>
                    </div>
                </div>
            </div>
        );
    }else if (props.resultMessageText === "processing") {
        if (props.isThereaServerResponse.trim() === "yes" || props.isThereaServerResponse.trim() === "initial") {        
            return (
                <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                    <div className="card-body">
                        <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                            <h5 style={{color:"#2e7d32"}}>Processing Result</h5>
                            Processing...
                        </div>
                    </div>
                </div>
            );
        }else{
            return (
                <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                    <div className="card-body">
                        <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                            <h5 style={{color:"#2e7d32"}}>Processing Result</h5>
                            Error processing this request...
                        </div>
                    </div>
                </div>
            );
        }
    }else{
        if (props.resultMessageText.trim() !== "") {
            return (
                <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                    <div className="card-body">
                        <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                            <h5 style={{color:"#2e7d32"}}>Processing Result</h5>
                            {props.resultMessageText}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

function ResultEurUsd(props) { 
    if (props.resultMessageText === "success") {
        return (
            <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                <div className="card-body">
                    <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                        <h5 style={{color:"#2e7d32"}}>In Quickbooks Online</h5><br/>
                        <h3>1st Transfer</h3><hr/>
                        Initiate the <strong>first transfer transaction</strong> and enter the following values and details
                        <br/><br/>
                        Transfer Funds From, select:<br/>
                        <span style={resultStyleRegular}>EUR Clydesdale - EUR</span><br/><br/>
                        Transfer Funds To, select:<br/>
                        <span style={resultStyleRegular}>GBP Interim Clearing A/C</span><br/><br/>
                        Currency, select:<br/>
                        <span style={resultStyleRegular}>EUR</span><br/><br/>
                        Date:<br/>
                        <span style={resultStyleDate}>Enter / select the actual transaction date</span><br/><br/>
                        FX Rate (EUR / GBP), enter:<br/>
                        <span style={resultStyleRegular}>{props.eurUsdFxRateEur}</span><br/><br/>
                        Transfer Amount, enter this value:<br/>
                        <span style={resultStyleRegular}>{props.eurUsdAmountEur}</span><br/><br/>
                        Memo, enter the following text:<br/>
                        <span style={resultStyleMemo}>{props.eurUsdMemoTextEur}</span><br/><br/>

                        <br/>
                        <h3>2nd Transfer</h3><hr/>
                        Initiate the <strong>second transfer transaction</strong> and enter the following values and details
                        <br/><br/>
                        Transfer Funds From, select:<br/>
                       
                        <span style={resultStyleRegular}>GBP Interim Clearing A/C</span><br/><br/>
                        Transfer Funds To, select:<br/>
                        <span style={resultStyleRegular}>USD Clydesdale - USD</span><br/><br/>
                        Currency, select:<br/>
                        <span style={resultStyleRegular}>USD</span><br/><br/>
                        Date:<br/>
                        <span style={resultStyleDate}>Enter / select the actual transaction date</span><br/><br/>
                        FX Rate (EUR / GBP), enter:<br/>
                        <span style={resultStyleRegular}>{props.eurUsdFxRateUsd}</span><br/><br/>
                        Transfer Amount, enter this value:<br/>
                        <span style={resultStyleRegular}>{props.eurUsdAmountUsd}</span><br/><br/>
                        Memo, enter the following text:<br/>
                       <span style={resultStyleRegular}>{props.eurUsdMemoTextUsd}</span>
                    </div>
                </div>
            </div>
        );
    }else if (props.resultMessageText === "processing") {
        if (props.isThereaServerResponse.trim() === "yes" || props.isThereaServerResponse.trim() === "initial") {        
            return (
                <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                    <div className="card-body">
                        <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                            <h5 style={{color:"#2e7d32"}}>Processing Result</h5>
                            Processing...
                        </div>
                    </div>
                </div>
            );
        }else{
            return (
                <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                    <div className="card-body">
                        <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                            <h5 style={{color:"#2e7d32"}}>Processing Result</h5>
                            Error processing this request...
                        </div>
                    </div>
                </div>
            );
        }
    }else{
        if (props.resultMessageText.trim() !== "") {
            return (
                <div className="card-single light-shadow" style={{border:"1px dotted #2e7d32"}}>
                    <div className="card-body">
                        <div className="fullDiv" style={{color:"black", fontSize:"0.8em"}}>
                            <h5 style={{color:"#2e7d32"}}>Processing Result</h5>
                            {props.resultMessageText}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

class FxTransactionsCalculator extends React.Component {
    state = {
        activeTab: 0,
    };

    handleChange = (event, activeTab) => {
        this.setState({ activeTab });
        this.setState({
            resultMessage:"",
            isThereaServerResponse:'initial',

            gbpusdGbp: '',
            gbpusdUsd: '',
            usdgbpUsd: '',
            usdgbpGbp: '',
            gbpeurGbp: '',
            gbpeurEur: '',
            eurgbpEur: '',
            eurgbpGbp: '',
            usdeurUsd: '',
            usdeurEur: '',
            usdeurInitialFx: '',
            eurusdEur: '',
            eurusdUsd: '',
            eurusdInitialFx: '',

            underProcess: '',

            resultFxGbpUsdError:"",
            resultFxGbpUsdAmount:"",
            resultFxGbpUsdFxRate:"",
            resultFxGbpUsdMemoText:"",

            resultFxUsdGbpError:'',
            resultFxUsdGbpAmount:'',
            resultFxUsdGbpFxRate:'',
            resultFxUsdGbpMemoText:'',

            resultFxGbpEurError:'',
            resultFxGbpEurAmount:'',
            resultFxGbpEurFxRate:'',
            resultFxGbpEurMemoText:'',

            resultFxEurGbpError:'',
            resultFxEurGbpAmount:'',
            resultFxEurGbpFxRate:'',
            resultFxEurGbpMemoText:'',

            resultFxUsdEurAmountUsd:'',
            resultFxUsdEurAmountEur:'',
            resultFxUsdEurFxRateUsd:'',
            resultFxUsdEurFxRateEur:'',
            resultFxUsdEurMemoTextUsd:'',
            resultFxUsdEurMemoTextEur:'',

            resultFxEurUsdAmountUsd:'',
            resultFxEurUsdAmountEur:'',
            resultFxEurUsdFxRateUsd:'',
            resultFxEurUsdFxRateEur:'',
            resultFxEurUsdMemoTextUsd:'',
            resultFxEurUsdMemoTextEur:''
        });
    };

    componentDidMount() {
        document.body.style.backgroundColor = "#fff";
        if(sessionStorage.getItem("userData")){
            if(JSON.parse(sessionStorage.getItem("userData")).userType==="OR"){
                this.setState({redirectToReferrer: true});
            }
        }else{
            this.setState({redirectToReferrer: true});
        }
        window.scrollTo(0, 0);
    }

    constructor(props){
        super(props);
        this.state = {
            resultMessage: '',
            isThereaServerResponse:'initial',

            gbpusdGbp: '',
            gbpusdUsd: '',
            usdgbpUsd: '',
            usdgbpGbp: '',
            gbpeurGbp: '',
            gbpeurEur: '',
            eurgbpEur: '',
            eurgbpGbp: '',
            usdeurUsd: '',
            usdeurEur: '',
            usdeurInitialFx: '',
            eurusdEur: '',
            eurusdUsd: '',
            eurusdInitialFx: '',

            underProcess: '',

            resultFxGbpUsdError:'',
            resultFxGbpUsdAmount:'',
            resultFxGbpUsdFxRate:'',
            resultFxGbpUsdMemoText:'',

            resultFxUsdGbpError:'',
            resultFxUsdGbpAmount:'',
            resultFxUsdGbpFxRate:'',
            resultFxUsdGbpMemoText:'',

            resultFxGbpEurError:'',
            resultFxGbpEurAmount:'',
            resultFxGbpEurFxRate:'',
            resultFxGbpEurMemoText:'',

            resultFxEurGbpError:'',
            resultFxEurGbpAmount:'',
            resultFxEurGbpFxRate:'',
            resultFxEurGbpMemoText:'',
            
            resultFxUsdEurAmountUsd:'',
            resultFxUsdEurAmountEur:'',
            resultFxUsdEurFxRateUsd:'',
            resultFxUsdEurFxRateEur:'',
            resultFxUsdEurMemoTextUsd:'',
            resultFxUsdEurMemoTextEur:'',

            resultFxEurUsdAmountUsd:'',
            resultFxEurUsdAmountEur:'',
            resultFxEurUsdFxRateUsd:'',
            resultFxEurUsdFxRateEur:'',
            resultFxEurUsdMemoTextUsd:'',
            resultFxEurUsdMemoTextEur:''
        };

        this.fxGbpUsd = this.fxGbpUsd.bind(this);
        this.fxUsdGbp = this.fxUsdGbp.bind(this);
        this.fxGbpEur = this.fxGbpEur.bind(this);
        this.fxEurGbp = this.fxEurGbp.bind(this);
        this.fxUsdEur = this.fxUsdEur.bind(this);
        this.fxEurUsd = this.fxEurUsd.bind(this);

        this.onChange = this.onChange.bind(this);
    }

    fxGbpUsd() {
        toast.dismiss();
        this.setState({ underProcess: 'gbpusd' });
        if(this.state.gbpusdGbp && this.state.gbpusdUsd){
            this.setState({ resultMessage:'processing' });

            PostData('gbpusd',this.state,'fxtransaction').then((result) => {
                // Should also be able to catch Timeout
                let responseJson = result;
                this.setState({isThereaServerResponse:"yes"});
                if(responseJson.serverRes){
                    let receivedServerResponse = result.serverRes;

                    if(receivedServerResponse.error === "T"){ // Check if "error" is true
                        this.setState({
                            resultMessage:receivedServerResponse.errMessage
                        });
                        toast.error(receivedServerResponse.errMessage, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined
                        });
                    } else { // Update the values of the page
                        this.setState({
                            resultMessage:"success",
                            resultFxGbpUsdAmount:receivedServerResponse.usdamount,
                            resultFxGbpUsdFxRate:receivedServerResponse.fxrate,
                            resultFxGbpUsdMemoText:receivedServerResponse.memotext
                        });
                    }
                } else {
                    this.setState({
                        resultMessage:'Error processing the request at this time, please try again',
                        resultFxGbpUsdError:'',
                        resultFxGbpUsdAmount:'',
                        resultFxGbpUsdFxRate:'',
                        resultFxGbpUsdMemoText:''
                    });
                    toast.error('Error processing the request at this time, please try again', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined
                    });
                }
            });
            // // We also need to check if sever-sent a response, if not shows an error message
        } else {
            this.setState({
                resultMessage:'',
                resultFxGbpUsdError:'',
                resultFxGbpUsdAmount:'',
                resultFxGbpUsdFxRate:'',
                resultFxGbpUsdMemoText:''
            });
            toast.error('You must enter both GBP sent and USD received', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }
    
    fxUsdGbp() {
        toast.dismiss();
        this.setState({ underProcess: 'usdgbp' });

        if(this.state.usdgbpUsd && this.state.usdgbpGbp){
            this.setState({ resultMessage:'processing' });

            PostData('usdgbp',this.state,'fxtransaction').then((result) => {
                // Should also be able to catch Timeout
                let responseJson = result;
                this.setState({isThereaServerResponse:"yes"});
                if(responseJson.serverRes){
                    let receivedServerResponse = result.serverRes;

                    if(receivedServerResponse.error === "T"){ // Check if "error" is true
                        this.setState({
                            resultMessage:receivedServerResponse.errMessage
                        });
                        toast.error(receivedServerResponse.errMessage, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined
                        });
                    } else { // Update the values of the page
                        this.setState({
                            resultMessage:"success",
                            resultFxUsdGbpAmount:receivedServerResponse.usdamount,
                            resultFxUsdGbpFxRate:receivedServerResponse.fxrate,
                            resultFxUsdGbpMemoText:receivedServerResponse.memotext
                        });
                    }
                } else {
                    this.setState({
                        resultMessage:'Error processing the request at this time, please try again',
                        resultFxUsdGbpError:'',
                        resultFxUsdGbpAmount:'',
                        resultFxUsdGbpFxRate:'',
                        resultFxUsdGbpMemoText:''
                    });
                    toast.error('Error processing the request at this time, please try again', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined
                    });
                }
            });
            // // We also need to check if sever-sent a response, if not shows an error message
        } else {
            this.setState({
                resultMessage:'',
                resultFxUsdGbpError:'',
                resultFxUsdGbpAmount:'',
                resultFxUsdGbpFxRate:'',
                resultFxUsdGbpMemoText:''
            });
            toast.error('You must enter both USD sent and GBP received', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }

    fxGbpEur() {
        toast.dismiss();
        this.setState({ underProcess: 'gbpeur' });
        if(this.state.gbpeurGbp && this.state.gbpeurEur){
            this.setState({ resultMessage:'processing' });

            PostData('gbpeur',this.state,'fxtransaction').then((result) => {
                // Should also be able to catch Timeout
                let responseJson = result;
                this.setState({isThereaServerResponse:"yes"});
                if(responseJson.serverRes){
                    let receivedServerResponse = result.serverRes;

                    if(receivedServerResponse.error === "T"){ // Check if "error" is true
                        this.setState({
                            resultMessage:receivedServerResponse.errMessage
                        });
                        toast.error(receivedServerResponse.errMessage, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined
                        });
                    } else { // Update the values of the page
                        this.setState({
                            resultMessage:"success",
                            resultFxGbpEurAmount:receivedServerResponse.euramount,
                            resultFxGbpEurFxRate:receivedServerResponse.fxrate,
                            resultFxGbpEurMemoText:receivedServerResponse.memotext
                        });
                    }
                } else {
                    this.setState({
                        resultMessage:'Error processing the request at this time, please try again',
                        resultFxGbpEurError:'',
                        resultFxGbpEurAmount:'',
                        resultFxGbpEurFxRate:'',
                        resultFxGbpEurMemoText:''
                    });
                    toast.error('Error processing the request at this time, please try again', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined
                    });
                }
            });
            // // We also need to check if sever-sent a response, if not shows an error message
        } else {
            this.setState({
                resultMessage:'',
                resultFxGbpEurError:'',
                resultFxGbpEurAmount:'',
                resultFxGbpEurFxRate:'',
                resultFxGbpEurMemoText:''
            });
            toast.error('You must enter both GBP sent and EUR received', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }

    fxEurGbp() {
        toast.dismiss();
        this.setState({ underProcess: 'eurgbp' });

        if(this.state.eurgbpEur && this.state.eurgbpGbp){
            this.setState({ resultMessage:'processing' });

            PostData('eurgbp',this.state,'fxtransaction').then((result) => {
                // Should also be able to catch Timeout
                let responseJson = result;
                this.setState({isThereaServerResponse:"yes"});
                if(responseJson.serverRes){
                    let receivedServerResponse = result.serverRes;

                    if(receivedServerResponse.error === "T"){ // Check if "error" is true
                        this.setState({
                            resultMessage:receivedServerResponse.errMessage
                        });
                        toast.error(receivedServerResponse.errMessage, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined
                        });
                    } else { // Update the values of the page
                        this.setState({
                            resultMessage:"success",
                            resultFxEurGbpAmount:receivedServerResponse.euramount,
                            resultFxEurGbpFxRate:receivedServerResponse.fxrate,
                            resultFxEurGbpMemoText:receivedServerResponse.memotext
                        });
                    }
                } else {
                    this.setState({
                        resultMessage:'Error processing the request at this time, please try again',
                        resultFxEurGbpError:'',
                        resultFxEurGbpAmount:'',
                        resultFxEurGbpFxRate:'',
                        resultFxEurGbpMemoText:''
                    });
                    toast.error('Error processing the request at this time, please try again', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined
                    });
                }
            });
            // // We also need to check if sever-sent a response, if not shows an error message
        } else {
            this.setState({
                resultMessage:'',
                resultFxEurGbpError:'',
                resultFxEurGbpAmount:'',
                resultFxEurGbpFxRate:'',
                resultFxEurGbpMemoText:''
            });
            toast.error('You must enter both EUR sent and GBP received', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }

    fxUsdEur() { 
        toast.dismiss();
        this.setState({ underProcess: 'usdeur' });
        if(this.state.usdeurUsd && this.state.usdeurEur && this.state.usdeurInitialFx){
            this.setState({ resultMessage:'processing' });

            PostData('usdeur',this.state,'fxtransaction').then((result) => {
                // Should also be able to catch Timeout
                let responseJson = result;
                this.setState({isThereaServerResponse:"yes"});
                if(responseJson.serverRes){
                    let receivedServerResponse = result.serverRes;

                    if(receivedServerResponse.error === "T"){ // Check if "error" is true
                        this.setState({
                            resultMessage:receivedServerResponse.errMessage
                        });
                        toast.error(receivedServerResponse.errMessage, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined
                        });
                    } else { // Update the values of the page
                        this.setState({
                            resultMessage:"success",
                            resultFxUsdEurAmountUsd:receivedServerResponse.usdamount,
                            resultFxUsdEurAmountEur:receivedServerResponse.euramount,
                            resultFxUsdEurFxRateUsd:receivedServerResponse.fxrateusd,
                            resultFxUsdEurFxRateEur:receivedServerResponse.fxrateeur,
                            resultFxUsdEurMemoTextUsd:receivedServerResponse.memotextusd,
                            resultFxUsdEurMemoTextEur:receivedServerResponse.memotexteur
                        });
                    }
                } else {
                    this.setState({
                        resultMessage:'Error processing the request at this time, please try again',
                        resultFxUsdEurError:'',
                        resultFxUsdEurAmountUsd:'',
                        resultFxUsdEurAmountEur:'',
                        resultFxUsdEurFxRateUsd:'',
                        resultFxUsdEurFxRateEur:'',
                        resultFxUsdEurMemoTextUsd:'',
                        resultFxUsdEurMemoTextEur:''
                    });
                    toast.error('Error processing the request at this time, please try again', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined
                    });
                }
            });
            // // We also need to check if sever-sent a response, if not shows an error message
        } else {
            this.setState({
                resultMessage:'',
                resultFxUsdEurError:'',
                resultFxUsdEurAmountUsd:'',
                resultFxUsdEurAmountEur:'',
                resultFxUsdEurFxRateUsd:'',
                resultFxUsdEurFxRateEur:'',
                resultFxUsdEurMemoTextUsd:'',
                resultFxUsdEurMemoTextEur:''
            });
            toast.error('You must enter both USD sent and EUR received. And also the EUR/GBP Exchange Rate', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }

    fxEurUsd() {
        toast.dismiss();
        this.setState({ underProcess: 'eurusd' });
        if(this.state.eurusdEur && this.state.eurusdUsd && this.state.eurusdInitialFx){
            this.setState({ resultMessage:'processing' });

            PostData('eurusd',this.state,'fxtransaction').then((result) => {
                // Should also be able to catch Timeout
                let responseJson = result;
                this.setState({isThereaServerResponse:"yes"});
                if(responseJson.serverRes){
                    let receivedServerResponse = result.serverRes;

                    if(receivedServerResponse.error === "T"){ // Check if "error" is true
                        this.setState({
                            resultMessage:receivedServerResponse.errMessage
                        });
                        toast.error(receivedServerResponse.errMessage, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined
                        });
                    } else { // Update the values of the page
                        this.setState({
                            resultMessage:"success",
                            resultFxEurUsdAmountEur:receivedServerResponse.euramount,
                            resultFxEurUsdAmountUsd:receivedServerResponse.usdamount,
                            resultFxEurUsdFxRateEur:receivedServerResponse.fxrateeur,
                            resultFxEurUsdFxRateUsd:receivedServerResponse.fxrateusd,
                            resultFxEurUsdMemoTextEur:receivedServerResponse.memotexteur,
                            resultFxEurUsdMemoTextUsd:receivedServerResponse.memotextusd
                        });
                    }
                } else {
                    this.setState({
                        resultMessage:'Error processing the request at this time, please try again',
                        resultFxEurUsdError:'',
                        resultFxEurUsdAmountUsd:'',
                        resultFxEurUsdAmountEur:'',
                        resultFxEurUsdFxRateUsd:'',
                        resultFxEurUsdFxRateEur:'',
                        resultFxEurUsdMemoTextUsd:'',
                        resultFxEurUsdMemoTextEur:''
                    });
                    toast.error('Error processing the request at this time, please try again', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined
                    });
                }
            });
            // // We also need to check if sever-sent a response, if not shows an error message
        } else {
            this.setState({
                resultMessage:'',
                resultFxEurUsdError:'',
                resultFxEurUsdAmountUsd:'',
                resultFxEurUsdAmountEur:'',
                resultFxEurUsdFxRateUsd:'',
                resultFxEurUsdFxRateEur:'',
                resultFxEurUsdMemoTextUsd:'',
                resultFxEurUsdMemoTextEur:''
            });
            toast.error('You must enter both EUR sent and USD received. And also the EUR/GBP Exchange Rate', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }

    onChange(e){
        this.setState({[e.target.name]:e.target.value});
    }
    
    render() {
        const { classes } = this.props;
        const { activeTab, inputValue } = this.state;

        if (this.state.redirectToReferrer) {
            if(JSON.parse(sessionStorage.getItem("userData")).userType==="OR") {
                return (<Navigate to={'/dashboard'}/>);
            }
            return (<Navigate to={'/'}/>);
        }

        if (sessionStorage.getItem("userData")===null || sessionStorage.getItem("userData")==="") {
            return (<Navigate to={'/'}/>);
        }
        const pageType = "system";
        let sessionvars="";
        // const userName = JSON.parse(sessionStorage.getItem("userData")).userName;
        
        sessionvars = {
            isDemo:false,
            loggedUserCid:JSON.parse(sessionStorage.getItem("userData")).cid,
            loggedUserEmail:JSON.parse(sessionStorage.getItem("userData")).emailAddress,
            loggedUserName:JSON.parse(sessionStorage.getItem("userData")).userName,
            loggedName:JSON.parse(sessionStorage.getItem("userData")).fullName,
            loggedUserType:JSON.parse(sessionStorage.getItem("userData")).userType,
            loggedUserDesignation:JSON.parse(sessionStorage.getItem("userData")).userDesignation
        }

        return (
            <>
            <title>FX Transactions Calculator - ERP | Britannia Beverages</title>
            <meta name="description" content="FX Transactions Calculator - ERP | Britannia Beverages" />
            <NavBar pageType={pageType}/>
            <div className="main-content">
                <SysHeaderbar sessionvars={sessionvars}/>
                <main>
                    <h2 className="dash-title">FX Transactions Calculator</h2>
                    
                    <div className={classes.root}>
                        <AppBar position="static" style={{ background: "#2e7d32" }}>
                            <Tabs
                                value={activeTab}
                                onChange={this.handleChange}
                                variant="scrollable"
                                scrollButtons="on"
                                indicatorColor="#fff;">
                            
                                <Tab label="GBP - USD" />
                                <Tab label="USD - GBP" />
                                <Tab label="GBP - EUR" />
                                <Tab label="EUR - GBP" />
                                <Tab label="USD - EUR" />
                                <Tab label="EUR - USD" />
                            </Tabs>
                        </AppBar>
                        <ToastContainer />
                        {activeTab === 0 && <TabContainer>
                            <div className="dash-cards-two">
                                <div className="card-single" style={{border:"0", backgroundColor:"transparent"}}>
                                    <div className="card-body">
                                        <div className="fullDiv">
                                            <h5>GBP to USD Foreign Exchange (FX) Transaction</h5>
                                            <CurrencyTextField
                                            label="GBP Sent"
                                            variant="outlined"
                                            value={inputValue}
                                            currencySymbol="£"
                                            outputFormat="string"
                                            ref={(el) => (this.gbpusdGbp = el)} name="gbpusdGbp" style={{marginTop:"30px"}} onChange={this.onChange}
                                            /><br/>
                                            <CurrencyTextField
                                            label="USD Received"
                                            variant="outlined"
                                            maximumValue="1500000"
                                            value={inputValue}
                                            currencySymbol="$"
                                            outputFormat="string"
                                            ref={(el) => (this.gbpusdUsd = el)} name="gbpusdUsd" style={{marginTop:"10px"}} onChange={this.onChange}
                                            /><br/>
                                            <Button variant="contained" color="success" style={{marginTop:"10px"}}  onClick={this.fxGbpUsd}>Process</Button>
                                        </div>
                                    </div>
                                </div>
                                <ResultGbpUsd isThereaServerResponse={this.state.isThereaServerResponse} resultMessageText={this.state.resultMessage} gbpUsdError={this.state.resultFxGbpUsdError} gbpUsdAmount={this.state.resultFxGbpUsdAmount} gbpUsdFxRate={this.state.resultFxGbpUsdFxRate} gbpUsdMemoText={this.state.resultFxGbpUsdMemoText} />
                            </div>
                        </TabContainer>}
                        {activeTab === 1 && <TabContainer>
                            <div className="dash-cards-two">
                                <div className="card-single" style={{border:"0", backgroundColor:"transparent"}}>
                                    <div className="card-body">
                                        <div className="fullDiv">
                                            <h5>USD to GBP Foreign Exchange (FX) Transaction</h5>
                                            <CurrencyTextField
                                            label="USD Sent"
                                            variant="outlined"
                                            value={inputValue}
                                            currencySymbol="$"
                                            outputFormat="string"
                                            ref={(el) => (this.usdgbpUsd = el)} name="usdgbpUsd" style={{marginTop:"30px"}} onChange={this.onChange}
                                            /><br/>
                                            <CurrencyTextField
                                            label="GBP Received"
                                            variant="outlined"
                                            maximumValue="1500000"
                                            value={inputValue}
                                            currencySymbol="£"
                                            outputFormat="string"
                                            ref={(el) => (this.usdgbpGbp = el)} name="usdgbpGbp" style={{marginTop:"10px"}} onChange={this.onChange}
                                            /><br/>
                                            <Button variant="contained" color="success" style={{marginTop:"10px"}}  onClick={this.fxUsdGbp}>Process</Button>
                                        </div>
                                    </div>
                                </div>
                                <ResultUsdGbp isThereaServerResponse={this.state.isThereaServerResponse} resultMessageText={this.state.resultMessage} usdGbpError={this.state.resultFxUsdGbpError} usdGbpAmount={this.state.resultFxUsdGbpAmount} usdGbpFxRate={this.state.resultFxUsdGbpFxRate} usdGbpMemoText={this.state.resultFxUsdGbpMemoText} />
                            </div>
                        </TabContainer>}
                        {activeTab === 2 && <TabContainer>
                            <div className="dash-cards-two">
                                <div className="card-single" style={{border:"0", backgroundColor:"transparent"}}>
                                    <div className="card-body">
                                        <div className="fullDiv">
                                            <h5>GBP to EUR Foreign Exchange (FX) Transaction</h5>
                                            <CurrencyTextField
                                            label="GBP Sent"
                                            variant="outlined"
                                            value={inputValue}
                                            currencySymbol="£"
                                            outputFormat="string"
                                            ref={(el) => (this.gbpeurGbp = el)} name="gbpeurGbp" style={{marginTop:"30px"}} onChange={this.onChange}
                                            /><br/>
                                            <CurrencyTextField
                                            label="EUR Received"
                                            variant="outlined"
                                            maximumValue="1500000"
                                            value={inputValue}
                                            currencySymbol="€"
                                            outputFormat="string"
                                            ref={(el) => (this.gbpeurEur = el)} name="gbpeurEur" style={{marginTop:"10px"}} onChange={this.onChange}
                                            /><br/>
                                            <Button variant="contained" color="success" style={{marginTop:"10px"}}  onClick={this.fxGbpEur}>Process</Button>
                                        </div>
                                    </div>
                                </div>
                                <ResultGbpEur isThereaServerResponse={this.state.isThereaServerResponse} resultMessageText={this.state.resultMessage} gbpEurError={this.state.resultFxGbpEurError} gbpEurAmount={this.state.resultFxGbpEurAmount} gbpEurFxRate={this.state.resultFxGbpEurFxRate} gbpEurMemoText={this.state.resultFxGbpEurMemoText} />
                            </div>
                        </TabContainer>}
                        {activeTab === 3 && <TabContainer>
                            <div className="dash-cards-two">
                                <div className="card-single" style={{border:"0", backgroundColor:"transparent"}}>
                                    <div className="card-body">
                                        <div className="fullDiv">
                                            <h5>EUR to GBP Foreign Exchange (FX) Transaction</h5>
                                            <CurrencyTextField
                                            label="EUR Sent"
                                            variant="outlined"
                                            value={inputValue}
                                            currencySymbol="€"
                                            outputFormat="string"
                                            ref={(el) => (this.eurgbpEur = el)} name="eurgbpEur" style={{marginTop:"30px"}} onChange={this.onChange}
                                            /><br/>
                                            <CurrencyTextField
                                            label="GBP Received"
                                            variant="outlined"
                                            maximumValue="1500000"
                                            value={inputValue}
                                            currencySymbol="£"
                                            outputFormat="string"
                                            ref={(el) => (this.eurgbpGbp = el)} name="eurgbpGbp" style={{marginTop:"10px"}} onChange={this.onChange}
                                            /><br/>
                                            <Button variant="contained" color="success" style={{marginTop:"10px"}}  onClick={this.fxEurGbp}>Process</Button>
                                        </div>
                                    </div>
                                </div>
                                <ResultEurGbp isThereaServerResponse={this.state.isThereaServerResponse} resultMessageText={this.state.resultMessage} eurGbpError={this.state.resultFxEurGbpError} eurGbpAmount={this.state.resultFxEurGbpAmount} eurGbpFxRate={this.state.resultFxEurGbpFxRate} eurGbpMemoText={this.state.resultFxEurGbpMemoText} />
                            </div>
                        </TabContainer>}
                        {activeTab === 4 && <TabContainer>
                            <div className="dash-cards-two">
                                <div className="card-single" style={{border:"0", backgroundColor:"transparent"}}>
                                    <div className="card-body">
                                        <div className="fullDiv">
                                            <h5>USD to EUR Foreign Exchange (FX) Transaction</h5>
                                            <CurrencyTextField
                                            label="USD Sent"
                                            variant="outlined"
                                            value={inputValue}
                                            currencySymbol="$"
                                            outputFormat="string"
                                            ref={(el) => (this.usdeurUsd = el)} name="usdeurUsd" style={{marginTop:"30px"}} onChange={this.onChange}
                                            /><br/>
                                            <CurrencyTextField
                                            label="EUR Received"
                                            variant="outlined"
                                            maximumValue="1500000"
                                            value={inputValue}
                                            currencySymbol="€"
                                            outputFormat="string"
                                            ref={(el) => (this.usdeurEur = el)} name="usdeurEur" style={{marginTop:"10px"}} onChange={this.onChange}
                                            /><br/>
                                            <CurrencyTextField
                                            label="EUR / GBP FX Rate"
                                            variant="outlined"
                                            decimalPlaces={12}
                                            maximumValue="1000"
                                            value={inputValue}
                                            currencySymbol=""
                                            outputFormat="string"
                                            ref={(el) => (this.usdeurInitialFx = el)} name="usdeurInitialFx" style={{marginTop:"10px"}} onChange={this.onChange}
                                            /><br/>
                                            <span style={{color:"black", fontSize:"0.8em"}}>* You can get this from Quickbooks</span><br/><br/>
                                            <Button variant="contained" color="success" style={{marginTop:"10px"}}  onClick={this.fxUsdEur}>Process</Button>
                                        </div>
                                    </div>
                                </div>
                                <ResultUsdEur isThereaServerResponse={this.state.isThereaServerResponse} resultMessageText={this.state.resultMessage} usdEurError={this.state.resultFxUsdEurError} usdEurAmountUsd={this.state.resultFxUsdEurAmountUsd} usdEurAmountEur={this.state.resultFxUsdEurAmountEur} usdEurFxRateUsd={this.state.resultFxUsdEurFxRateUsd} usdEurFxRateEur={this.state.resultFxUsdEurFxRateEur} usdEurMemoTextUsd={this.state.resultFxUsdEurMemoTextUsd} usdEurMemoTextEur={this.state.resultFxUsdEurMemoTextEur} />
                            </div>
                        </TabContainer>}
                        {activeTab === 5 && <TabContainer>
                            <div className="dash-cards-two">
                                <div className="card-single" style={{border:"0", backgroundColor:"transparent"}}>
                                    <div className="card-body">
                                        <div className="fullDiv">
                                            <h5>EUR to USD Foreign Exchange (FX) Transaction</h5>
                                            <CurrencyTextField
                                            label="EUR Sent"
                                            variant="outlined"
                                            value={inputValue}
                                            currencySymbol="€"
                                            outputFormat="string"
                                            ref={(el) => (this.eurusdEur = el)} name="eurusdEur" style={{marginTop:"30px"}} onChange={this.onChange}
                                            /><br/>
                                            <CurrencyTextField
                                            label="USD Received"
                                            variant="outlined"
                                            maximumValue="1500000"
                                            value={inputValue}
                                            currencySymbol="$"
                                            outputFormat="string"
                                            ref={(el) => (this.eurusdUsd = el)} name="eurusdUsd" style={{marginTop:"10px"}} onChange={this.onChange}
                                            /><br/>
                                            <CurrencyTextField
                                            label="EUR / GBP FX Rate"
                                            variant="outlined"
                                            maximumValue="1000"
                                            decimalPlaces={12}
                                            value={inputValue}
                                            currencySymbol=""
                                            outputFormat="string"
                                            ref={(el) => (this.eurusdInitialFx = el)} name="eurusdInitialFx" style={{marginTop:"10px"}} onChange={this.onChange}
                                            /><br/>
                                            <span style={{color:"black", fontSize:"0.8em"}}>* You can get this from Quickbooks</span><br/><br/>
                                            <Button variant="contained" color="success" style={{marginTop:"10px"}}  onClick={this.fxEurUsd}>Process</Button>
                                        </div>
                                    </div>
                                </div>
                                <ResultEurUsd isThereaServerResponse={this.state.isThereaServerResponse} resultMessageText={this.state.resultMessage} eurUsdError={this.state.resultFxEurUsdError} eurUsdAmountEur={this.state.resultFxEurUsdAmountEur} eurUsdAmountUsd={this.state.resultFxEurUsdAmountUsd} eurUsdFxRateEur={this.state.resultFxEurUsdFxRateEur} eurUsdFxRateUsd={this.state.resultFxEurUsdFxRateUsd} eurUsdMemoTextEur={this.state.resultFxEurUsdMemoTextEur} eurUsdMemoTextUsd={this.state.resultFxEurUsdMemoTextUsd} />
                            </div>
                        </TabContainer>}
                    </div>
                </main>
                <PageFooter pageType={pageType}/>
            </div>
            </>
        );
    }
}

FxTransactionsCalculator.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FxTransactionsCalculator);