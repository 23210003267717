import React, { Component } from 'react';
import { Navigate, Link } from 'react-router-dom';
import '../../styles/SystemStyles.css';
import { PostData } from '../../datalink/PostData';
// import UserFeed from "../../UserFeed";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from '../NavBar';
import SysHeaderbar from '../SysHeaderbar';
import PageFooter from '../PageFooter';
import PageBanner from '../PageBanner';
import SysDemoContents from '../SysDemoContents';
import '../../styles/AccountProfile.css';

// toast.configure();
class MyAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data:[],
            redirectToReferrer: false
        };
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#fff";
        if(sessionStorage.getItem("userData")){
            if(JSON.parse(sessionStorage.getItem("userData")).userType==="OR"){
                this.setState({redirectToReferrer: true});
            }
        }else{
            this.setState({redirectToReferrer: true});
        }
        window.scrollTo(0, 0);
    }

    render() {
        if (this.state.redirectToReferrer) {
            if(JSON.parse(sessionStorage.getItem("userData")).userType==="OR") {
                return (<Navigate to={'/dashboard'}/>);
            }
            return (<Navigate to={'/'}/>);
        }

        if (sessionStorage.getItem("userData")==null || sessionStorage.getItem("userData")=="") {
            return (<Navigate to={'/'}/>);
        }
        const pageType = "system";
        let sessionvars="";
        const userName = JSON.parse(sessionStorage.getItem("userData")).userName;
        
        if(userName.toUpperCase()==="DEMOLOGIN"){
            sessionvars = {
                isDemo:true,
                demoComponents:"demoMyAccount",
                loggedUserName:JSON.parse(sessionStorage.getItem("userData")).userName,
                loggedName:JSON.parse(sessionStorage.getItem("userData")).fullName,
                loggedUserDesignation:JSON.parse(sessionStorage.getItem("userData")).userDesignation
            }
        }else{
            sessionvars = {
                isDemo:false,
                loggedUserCid:JSON.parse(sessionStorage.getItem("userData")).cid,
                loggedUserEmail:JSON.parse(sessionStorage.getItem("userData")).emailAddress,
                loggedUserName:JSON.parse(sessionStorage.getItem("userData")).userName,
                loggedName:JSON.parse(sessionStorage.getItem("userData")).fullName,
                loggedUserType:JSON.parse(sessionStorage.getItem("userData")).userType,
                loggedUserDesignation:JSON.parse(sessionStorage.getItem("userData")).userDesignation
            }
        }

        return (
            <>
            <title>My Account - ERP | Britannia Beverages</title>
            <meta name="description" content="My Account - ERP | Britannia Beverages" />
            <NavBar pageType={pageType}/>
            <div className="main-content">
                <SysHeaderbar sessionvars={sessionvars}/>
                <main>
                    {/* <h2 className="dash-title">My Account</h2> */}
                    <PopulateThisPage sessionvars={sessionvars} />
                </main>
                <PageFooter pageType={pageType}/>
            </div>
            </>
        );
    }
}

class PopulateThisPage extends Component {
    render() {
        // if(this.props.sessionvars.isDemo){
        //     return (<SysDemoContents {...this.props.sessionvars}/>);
        // }else{
            return (
                <>
                <div class="profilecard">
                    {/* From Here */}
                    <div class="card">
                        <div class="banner">
                        <img src="/img/users/sanny-pic.jpg" alt="" />
                        </div>
                        <div class="menu">
                            <div class="opener"><span></span><span></span><span></span></div>
                        </div>
                        <h2 class="name">Sanny Dela Rosa</h2>
                        <div class="title">Operations Manager</div>
                        <div class="mobile">+63 921 7087468</div>
                        <div class="email">sannyd@britanniabeverages.com</div>
                        <div class="actions">
                            <div class="follow-info">
                            <h2><Link to='/my-account/change-mobile'><small>Change Mobile Number</small></Link></h2>
                            <h2><Link to='/my-account/change-email'><small>Change Email Address</small></Link></h2>
                            </div>
                            <div class="follow-btn">
                                <Link to='/my-account/change-password'><button>Change Password</button></Link>
                            </div>
                        </div>
                        <div class="desc">Full system access</div>
                        </div>
                    {/* To Here */}
                </div>
            </>
            )
        // }
    }
}

export default MyAccount;