import React, { Component } from 'react';
import { Navigate, Link } from 'react-router-dom';
import '../../styles/SystemStyles.css';
import { PostData } from '../../datalink/PostData';
// import UserFeed from "../../UserFeed";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from '../NavBar';
import SysHeaderbar from '../SysHeaderbar';
import PageFooter from '../PageFooter';
import PageBanner from '../PageBanner';

// toast.configure();
class ReportsGeneration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data:[],
            redirectToReferrer: false
        };
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#fff";
        if(sessionStorage.getItem("userData")){
            if(JSON.parse(sessionStorage.getItem("userData")).userType==="OR"){
                this.setState({redirectToReferrer: true});
            }
        }else{
            this.setState({redirectToReferrer: true});
        }
        window.scrollTo(0, 0);
    }

    render() {
        if (this.state.redirectToReferrer) {
            if(JSON.parse(sessionStorage.getItem("userData")).userType==="OR") {
                return (<Navigate to={'/dashboard'}/>);
            }
            return (<Navigate to={'/'}/>);
        }

        if (sessionStorage.getItem("userData")==null || sessionStorage.getItem("userData")=="") {
            return (<Navigate to={'/'}/>);
        }
        const pageType = "system";
        let sessionvars="";
        const userName = JSON.parse(sessionStorage.getItem("userData")).userName;
        
        sessionvars = {
            isDemo:false,
            loggedUserCid:JSON.parse(sessionStorage.getItem("userData")).cid,
            loggedUserEmail:JSON.parse(sessionStorage.getItem("userData")).emailAddress,
            loggedUserName:JSON.parse(sessionStorage.getItem("userData")).userName,
            loggedName:JSON.parse(sessionStorage.getItem("userData")).fullName,
            loggedUserType:JSON.parse(sessionStorage.getItem("userData")).userType,
            loggedUserDesignation:JSON.parse(sessionStorage.getItem("userData")).userDesignation
        }

        return (
            <>
            <title>Reports Generation - ERP | Britannia Beverages</title>
            <meta name="description" content="Reports Generation - ERP | Britannia Beverages" />
            <NavBar pageType={pageType}/>
            <div className="main-content">
                <SysHeaderbar sessionvars={sessionvars}/>
                <main>
                    <h2 className="dash-title">Reports Generation</h2>
                    <PopulateThisPage sessionvars={sessionvars} />
                </main>
                <PageFooter pageType={pageType}/>
            </div>
            </>
        );
    }
}

class PopulateThisPage extends Component {
    render() {
        return (
            <>
            <ToastContainer />
            <div className="dash-cards">
                <div className="card-single light-shadow">
                    <div className="card-body">
                        <div className="fullDiv">
                            <img className="inset-customerlogo" src="/img/reports/hmrc-logo1.png" alt=""/>
                            <h5>IPR Suspension Return - UK</h5>
                            <h6>Quarterly</h6>
                        </div>
                    </div>
                    <div className="card-footer">
                        <ul>
                            <li><Link to="/reports-generation/bod-uk"><span><i className="fas fa-print"></i></span> Generate</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="card-single light-shadow">
                    <div className="card-body">
                        <div className="fullDiv">
                        <img className="inset-customerlogo" src="/img/reports/revenue-ie-logo1.png" alt=""/>
                            <h5>IPR Suspension Return - EU</h5>
                            <h6>Quarterly</h6>
                        </div>
                    </div>
                    <div className="card-footer">
                        <ul>
                            <li><Link to="/reports-generation/bod-eu"><span><i className="fas fa-print"></i></span> Generate</Link></li>
                        </ul>
                    </div>
                </div>
                
                <div className="card-single light-shadow">
                    <div className="card-body">
                        <div className="fullDiv">
                            <img className="inset-customerlogo" src="/img/reports/hmrc-logo1.png" alt=""/>
                            <h5>UK Export - Import Mapping</h5>
                            <h6>as Needed</h6>
                        </div>
                    </div>
                    <div className="card-footer">
                        <ul>
                            <li><Link to="/reports-generation/uk-ipr-mapping"><span><i className="fas fa-print"></i></span> Generate</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="card-single light-shadow">
                    <div className="card-body">
                        <div className="fullDiv">
                            <img className="inset-customerlogo" src="/img/reports/environment-agency-logo1.jpg" alt=""/>
                            <h5>PRN</h5>
                            <h6>as Needed</h6>
                        </div>
                    </div>
                    <div className="card-footer">
                        <ul>
                            <li><Link to="/reports-generation/prn">Actual</Link></li>
                            <li><Link to="/reports-generation/prn">Forecast</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default ReportsGeneration;