import React, { useState, useEffect } from 'react';
import { Button } from './Button'; // This is being use by other pages
import { Navigate, Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import './Navbar.css';
// import '../styles/PropertyManagers.css';
import '../styles/react-confirm-alert.css';

function GenericMenu(props) {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [navbar, setNavbar] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const changeBackground = () => {
    if(window.scrollY >= 100) {
        setNavbar(true);
    } else{
        setNavbar(false);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);
  window.addEventListener('scroll', changeBackground);
 
  const IsLogged = () => {
    if(sessionStorage.getItem("userData")){
      const userType = JSON.parse(sessionStorage.getItem("userData")).userType;
        return (
        <>
        <li className="btn">
          <Link to='/dashboard' onClick={closeMobileMenu}><i className="fas fa-laptop-house"></i> &nbsp;Go To Dashboard</Link>
        </li>
        </>
        );
    }
  };
  return (
    <>
      <header className={navbar ? 'navbar active' : 'navbar'}>
        <div className="contentsBlock">
          <div className="navbar-wrap">
            <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
              <img src="/img/bblogo1.png" className="hero-img" alt="ERP Logo"/>
            </Link>
            <nav className="nav">
              <div className='mobile-menu-icon' onClick={handleClick}>
                <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
              </div>
              <ul className={click ? 'collapsed is-expanded' : 'collapsed'}>
                  <li><Link to='/contact-us' onClick={closeMobileMenu}>Contact Us</Link></li>
                  <IsLogged/>
                  {/* <li className="btn"><IsLogged/></li> */}
                </ul>      
              </nav>
          </div>
        </div>
      </header>
    </>
  );
}

function SystemAll(props) {
  const [isRedirect, setIsRedirect] = useState(false);
  const logoutConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="dialogBox">
            <p style={{textAlign:"center"}}>Are you sure you want to logout?</p>
            <div className="buttonDiv">
              <button style={{marginRight:"10px"}} className="banner-btn" onClick={() => {
                sessionStorage.setItem("userData",'');
                sessionStorage.clear();
                setIsRedirect(true);
                onClose();
              }}>Yes</button>
              <button className="banner-btn two" onClick={() => {onClose();}}>No</button>
            </div>
          </div>
        );
      }
    });
  }

  if(isRedirect === true){
    return <Navigate to='/' />
  }

  return (
    <>
    <input type="checkbox" id="sidebar-toggle"/>
    <div className="sidebar scrollbar moderate-shadow" id="style15">
      <div className="sidebar-header">
        <h3 className="brand">
          <span className="ti-unlink"></span>
          <span><img src="/img/bblogo2.png"/></span>
        </h3> 
        <label htmlFor="sidebar-toggle"><i className="fas fa-exchange-alt"></i></label>
      </div>
      <div className="sidebar-menu">
        <ul>
          <li><Link to='/dashboard'><i className="fas fa-columns"></i> <span>Dashboard</span></Link></li>
          <li><Link to='/customers'><i className="fas fa-handshake"></i> <span>Customers</span></Link></li>
          <li><Link to='/suppliers'><i className="fas fa-truck"></i> <span>Suppliers</span></Link></li>
          <li><Link to='/payables-monitoring'><i className="fas fa-file-invoice-dollar"></i> <span>Payables Monitoring</span></Link></li>
          <li><Link to='/reports-generation'><i className="fas fa-file"></i> <span>Report Generation</span></Link></li>
          <li><Link to='/shipping-rates'><i className="fas fa-ship"></i> <span>Shipping Rates</span></Link></li>
          <li><Link to='/negotiations'><i className="fas fa-comments-dollar"></i> <span>Negotiations</span></Link></li>
          <li className="marketplace"><Link to='/how-to'><i className="fas fa-clipboard"></i> <span>How-To Guides</span></Link></li>
          {/* <li className="logoutbtn"><button className="btn" onClick={logoutConfirm}><i className="fas fa-power-off"></i><span>&nbsp;Logout</span></button></li> */}
        </ul>
      </div>
    </div>
    </>
  );
}

function SystemShippingLine(props) {

}

function Navbar(props) {
  const pageType = props.pageType;
  if (pageType==="system") {
    return <SystemAll />;
  }else { // Generic
    return <GenericMenu />;
  }
}

export default Navbar;