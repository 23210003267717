import React, { Component } from 'react';
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import NavBar from '../NavBar';
import PageFooter from '../PageFooter';

const IsLogged = () => {
    if(sessionStorage.getItem("userData")){
		const userType = JSON.parse(sessionStorage.getItem("userData")).userType;
		if(userType) { // Logged
			return (
			<>
			<Link to='/dashboard'>
				<button className="buttonMain banner-btn">
				<i className="fas fa-laptop-house"></i> &nbsp; Go to Dashboard
				</button>
			</Link>
			</>
			)
		}else{ // Not Logged, show login button
			return (
				<>
				<Link to='/'>
					<button className="buttonMain banner-btn">
					<i className="fas fa-sign-in-alt"></i> &nbsp; Go to Login Page
					</button>
				</Link>
				</>
			)
		}
    } else {
		return (
			<>
     		<Link to='/'>
				<button className="buttonMain banner-btn">
					<i className="fas fa-sign-in-alt"></i> &nbsp; Go to Login Page
				</button>
			</Link>
			</>
		)
    }
};

class NotFound extends Component {
    componentDidMount(){
        document.body.style.backgroundColor = "#01203f";
        window.scrollTo(0, 0);
    }

    render() {
        const pageType = "generic";
        return (
            <>
            <title>Page not found - ERP | Britannia Beverages</title>
            <meta name="description" content="Page not found - ERP | Britannia Beverages" />
        
            <NavBar pageType={pageType}/>
            <section className="genericSection">
                <div className="contentsBlock" style={{minHeight:"63vh"}}>
                    <div className="bMEOtR">
                        <div className="ffYYIb col imgfiller">
                            <img src="/img/erplogo.png" className="about-thumb" alt="ERP | Britannia Beverages"/>
                        </div>
                        <div className="Col__ColWrapper-ryzggq-0 ffYYIb col about-content-wrap">
                            <div className="SectionTitle__TitleWrap-sc-6a9yeo-0 EQkxe section-title contentMinusFiller">
                                <h4>Page Not Found</h4>
                                <h2>The page you are trying to access is either currently not available or doesn't exist</h2>
                                <IsLogged />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PageFooter pageType={pageType}/>
            </>
        );
    }
}
export default NotFound;