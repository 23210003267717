import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Navigate, Link } from 'react-router-dom';
import '../styles/SystemStyles.css';

function SysHeaderbar(props) {
  // const petList = Object.entries((props.sessionvars).map(([key,value])=>{
  //   return (
  //       <div>{key} : {value.toString()}</div>
  //   );
  // })
  // value.toString()

  // {Object.keys(props.sessionvars).map((sessionvar, i) => (
  //   console.log("A "+sessionvar+" X "+props.sessionvars[sessionvar])
  // ))}

  const loggedName = Object.keys(props.sessionvars).map((sessionvar, i) => {
    if (sessionvar === "loggedName") {
      return (<>{props.sessionvars[sessionvar]}</>);
    }
  });
  const loggedUserDesignation = Object.keys(props.sessionvars).map((sessionvar, i) => {
    if (sessionvar === "loggedUserDesignation") {
      return (<>{props.sessionvars[sessionvar]}</>);
    }
  });

    const [isRedirect, setIsRedirect] = useState(false);
    const logoutConfirm = () => {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="dialogBox">
                <p style={{textAlign:"center"}}>Are you sure you want to logout?</p>
                <div className="buttonDiv">
                  <button style={{marginRight:"10px"}} className="banner-btn" onClick={() => {
                    sessionStorage.setItem("userData",'');
                    sessionStorage.clear();
                    setIsRedirect(true);
                    onClose();
                  }}>Yes</button>
                  <button className="banner-btn two" onClick={() => {onClose();}}>No</button>
                </div>
              </div>
            );
          }
        });
    }
    if(isRedirect === true){
        return <Navigate to='/' />
    }

    const DisplayLoggedUser = () => {
        return (
            <div className="headerbaradmin">
                <div className="loggedUserInfoName">
                    <img className="inset-img" src="/img/users/sanny-pic.jpg" alt="" style={{float:"left", height:"50px", width:"50px"}}/>
                    <div style={{margin:"10px 5px 0 60px"}}>
                        
                        <div className="dropdown">
                            {loggedName}<label className="dropbtn"><i className="fas fa-caret-down"></i></label>
                            <ul className="teamDesignationDropMenu light-shadow">
                                <li><Link to='/my-account'><div className="btn"><i className="fas fa-star"></i> My Account</div></Link></li>
                                {/* <li><Link to='/invite-materials'><div className="btn"><i className="fas fa-smile"></i> Invite Materials</div></Link></li> */}
                                <li><button className="btn" onClick={logoutConfirm}><i className="fas fa-power-off"></i> Logout</button></li>
                            </ul>
                        </div>
                        <h6>{loggedUserDesignation}</h6>
                    </div>
                    
                    {/* <div>
                        Alexander de Silva, <span className="headerTeamDesignation">Property Manager</span> <label onClick={dropdownMenu}><i className="fas fa-caret-down" style={{fontSize:"1.2em"}}></i></label>
                    </div> */}
                </div>
                <div className="loggedUserInfoDesc">
                    {/* Nothing here */}
                </div>
            </div>
        );
    };

    return <DisplayLoggedUser />;
}

export default SysHeaderbar;