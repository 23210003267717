import React, { Component } from 'react';
import { Navigate, Link } from 'react-router-dom';
import '../../styles/SystemStyles.css';
import { PostData } from '../../datalink/PostData';
// import UserFeed from "../../UserFeed";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from '../NavBar';
import SysHeaderbar from '../SysHeaderbar';
import PageFooter from '../PageFooter';
import PageBanner from '../PageBanner';

// toast.configure();
class HowToGuides extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data:[],
            redirectToReferrer: false
        };
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#fff";
        if(sessionStorage.getItem("userData")){
            if(JSON.parse(sessionStorage.getItem("userData")).userType==="OR"){
                this.setState({redirectToReferrer: true});
            }
        }else{
            this.setState({redirectToReferrer: true});
        }
        window.scrollTo(0, 0);
    }

    render() {
        if (this.state.redirectToReferrer) {
            if(JSON.parse(sessionStorage.getItem("userData")).userType==="OR") {
                return (<Navigate to={'/dashboard'}/>);
            }
            return (<Navigate to={'/'}/>);
        }

        if (sessionStorage.getItem("userData")==null || sessionStorage.getItem("userData")=="") {
            return (<Navigate to={'/'}/>);
        }
        const pageType = "system";
        let sessionvars="";
        const userName = JSON.parse(sessionStorage.getItem("userData")).userName;
        
        sessionvars = {
            isDemo:false,
            loggedUserCid:JSON.parse(sessionStorage.getItem("userData")).cid,
            loggedUserEmail:JSON.parse(sessionStorage.getItem("userData")).emailAddress,
            loggedUserName:JSON.parse(sessionStorage.getItem("userData")).userName,
            loggedName:JSON.parse(sessionStorage.getItem("userData")).fullName,
            loggedUserType:JSON.parse(sessionStorage.getItem("userData")).userType,
            loggedUserDesignation:JSON.parse(sessionStorage.getItem("userData")).userDesignation
        }

        return (
            <>
            <title>How-To Guides - ERP | Britannia Beverages</title>
            <meta name="description" content="How-To Guides - ERP | Britannia Beverages" />
            <NavBar pageType={pageType}/>
            <div className="main-content">
                <SysHeaderbar sessionvars={sessionvars}/>
                <main>
                    <h2 className="dash-title">How-To Guides</h2>
                    <PopulateThisPage sessionvars={sessionvars} />
                </main>
                <PageFooter pageType={pageType}/>
            </div>
            </>
        );
    }
}

class PopulateThisPage extends Component {
    render() {
        return (
            <>
            <ToastContainer />
            <div className="dash-cards">
                <div className="card-single light-shadow">
                    <div className="card-body">
                        <div className="fullDiv">
                            <img className="inset-customerlogo" src="/img/how-to-guides/clydesdale-bank-logo1.jpg" alt=""/>
                            <h5>Invoice Finance (IF) Report</h5>
                            <h6>Monthly</h6>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="">How</Link>
                    </div>
                </div>
                <div className="card-single light-shadow">
                    <div className="card-body">
                        <div className="fullDiv">
                        <img className="inset-customerlogo" src="/img/how-to-guides/quickbooks-logo1.jpg" alt=""/>
                            <h5>FX Transactions</h5>
                            <h6>as Needed</h6>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="">How</Link>
                    </div>
                </div>
                
                <div className="card-single light-shadow">
                    <div className="card-body">
                        <div className="fullDiv">
                            <img className="inset-customerlogo" src="/img/customers/maaden-logo1.jpg" alt=""/>
                            <h5>Uploading of Invoice to Maaden Oracle System</h5>
                            <h6>as Needed</h6>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="">How</Link>
                    </div>
                </div>
                <div className="card-single light-shadow">
                    <div className="card-body">
                        <div className="fullDiv">
                            <img className="inset-customerlogo" src="/img/customers/uacj-logo1.png" alt=""/>
                            <h5>Uploading of Invoice to UACJ SAP System</h5>
                            <h6>as Needed</h6>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="">How</Link>
                    </div>
                </div>
                <div className="card-single light-shadow">
                    <div className="card-body">
                        <div className="fullDiv">
                            <img className="inset-customerlogo" src="/img/how-to-guides/eu-logo1.jpg" alt=""/>
                            <h5>Request INF in the EU Online System</h5>
                            <h6>as Needed</h6>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="">How</Link>
                    </div>
                </div>
                <div className="card-single light-shadow">
                    <div className="card-body">
                        <div className="fullDiv">
                            <img className="inset-customerlogo" src="/img/how-to-guides/quickbooks-logo1.jpg" alt=""/>
                            <h5>Management Report</h5>
                            <h6>as Needed</h6>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="">How</Link>
                    </div>
                </div>
                <div className="card-single light-shadow">
                    <div className="card-body">
                        <div className="fullDiv">
                            <img className="inset-customerlogo" src="/img/reports/environment-agency-logo1.jpg" alt=""/>
                            <h5>PRN Quarterly Report Submission</h5>
                            <h6>Quarterly</h6>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="">How</Link>
                    </div>
                </div>
                <div className="card-single light-shadow">
                    <div className="card-body">
                        <div className="fullDiv">
                            <img className="inset-customerlogo" src="/img/reports/environment-agency-logo1.jpg" alt=""/>
                            <h5>PRN Annual Report Submission</h5>
                            <h6>Annually</h6>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="">How</Link>
                    </div>
                </div>
                <div className="card-single light-shadow">
                    <div className="card-body">
                        <div className="fullDiv">
                            <img className="inset-customerlogo" src="/img/how-to-guides/cns-logo1.jpg" alt=""/>
                            <h5>VGM Submission in CNS</h5>
                            <h6>as Needed</h6>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="">How</Link>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default HowToGuides;