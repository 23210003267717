import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/SystemStyles.css';

function DemoMessage(props) {
    const userType = props.userType;
    return (
        <div className="dashcardsprototype">
            <div className="card-single" style={{lineHeight:"1.4em", fontSize:"0.8em"}}>
                This is a prototype version. Some pages and features are not functional (contents of this this page are just dummy).
            </div>
        </div>
    );
}
  
function PageBanner(props) {
    const bannerType = props.bannerType;
    if (bannerType === "demomessage") {
      return <DemoMessage userType={props.userType}/>;
    }
}

export default PageBanner;