import React from 'react';
import {Route, Routes} from 'react-router-dom';
import LoginPage from './components/pages/LoginPage';
// import ContactUs from './components/pages/ContactUs';

import SysDashboard from './components/pages/SysDashboard';
import MyAccount from './components/pages/MyAccount';
import MyAccountChangePass from './components/pages/MyAccountChangePass';

import SuppliersCategory from './components/pages/SuppliersCategory';
import SuppliersMetal from './components/pages/SuppliersMetal';
import SuppliersIpr from './components/pages/SuppliersIpr';
import CustomersCategory from './components/pages/CustomersCategory';
import CustomersMetal from './components/pages/CustomersMetal';
import CustomersIpr from './components/pages/CustomersIpr';
import CustomersPrn from './components/pages/CustomersPrn';
import ShippingRatesCategory from './components/pages/ShippingRatesCategory';
import ShippingRatesBookingAgents from './components/pages/ShippingRatesBookingAgents';
import ShippingRatesShippingLines from './components/pages/ShippingRatesShippingLines';
import PayablesMonitoring from './components/pages/PayablesMonitoring';
import ReportsGeneration from './components/pages/ReportsGeneration';
import AgreementsCategory from './components/pages/AgreementsCategory';
import HowToCategory from './components/pages/HowToCategory';
import FrequentlyAskedQuestions from './components/pages/FrequentlyAskedQuestions';
import OnlineTools from './components/pages/OnlineTools';
import HowToGuides from './components/pages/HowToGuides';
import DownloadableForms from './components/pages/DownloadableForms';
import WorldClocks from './components/pages/WorldClocks';
import FxTransactionsCalculator from './components/pages/FxTransactionsCalculator';

import SupplierProfileMetalTrading from './components/pages/SupplierProfileMetalTrading';
import SupplierShipmentsList from './components/pages/SupplierShipmentsList';
import SupplierShipmentsView from './components/pages/SupplierShipmentsView';
import SupplierShipmentsAddNew from './components/pages/SupplierShipmentsAddNew';
import SupplierShipmentsEdit from './components/pages/SupplierShipmentsEdit';
import SupplierShipmentsDelete from './components/pages/SupplierShipmentsDelete';
import SupplierProfileIpr from './components/pages/SupplierProfileIpr';

import CustomerProfileMetalTrading from './components/pages/CustomerProfileMetalTrading';
import CustomerShipmentsList from './components/pages/CustomerShipmentsList';
import CustomerProfileIpr from './components/pages/CustomerProfileIpr';
import CustomerProfilePrn from './components/pages/CustomerProfilePrn';

import NotFound from './components/pages/NotFound';

import ForceLogout from './components/ForceLogout';
import './App.css';

const SiteRoutes = () => (
  <Routes>
    {/* Generic Pages */}
    {/* <Route exact path='/contact-us' element={<ContactUs/>} /> */}
    <Route exact path='/' element={<LoginPage/>} />
    
    <Route exact path='/my-account' element={<MyAccount/>} />
    <Route exact path='/my-account/change-password' element={<MyAccountChangePass/>} />

    <Route exact path='/dashboard' element={<SysDashboard/>} />
    <Route exact path='/suppliers' element={<SuppliersCategory/>} />
    <Route exact path='/suppliers-metal-trading' element={<SuppliersMetal/>} />
    <Route exact path='/suppliers-ipr' element={<SuppliersIpr/>} />
    <Route exact path='/customers' element={<CustomersCategory/>} />
    <Route exact path='/customers-metal-trading' element={<CustomersMetal/>} />
    <Route exact path='/customers-ipr' element={<CustomersIpr/>} />
    <Route exact path='/customers-prn' element={<CustomersPrn/>} />
    <Route exact path='/shipping-rates' element={<ShippingRatesCategory/>} />
    <Route exact path='/shipping-rates-booking-agents' element={<ShippingRatesBookingAgents/>} />
    <Route exact path='/shipping-rates-shipping-line-direct' element={<ShippingRatesShippingLines/>} />
    <Route exact path='/payables-monitoring' element={<PayablesMonitoring/>} />
    <Route exact path='/reports-generation' element={<ReportsGeneration/>} />
    <Route exact path='/negotiations' element={<AgreementsCategory/>} />
    <Route exact path='/how-to' element={<HowToCategory/>} />
    <Route exact path='/frequently-asked-questions' element={<FrequentlyAskedQuestions/>} />
    <Route exact path='/online-tools' element={<OnlineTools/>} />
    <Route exact path='/how-to-guides' element={<HowToGuides/>} />
    <Route exact path='/downloadable-forms-templates' element={<DownloadableForms/>} />
    <Route exact path='/online-tools/world-clocks' element={<WorldClocks/>} />
    <Route exact path='/online-tools/fx-transactions-calc' element={<FxTransactionsCalculator/>} />

    <Route path="/suppliers-metal-trading/profile/:supplierId" element={<SupplierProfileMetalTrading/>}/>
    <Route path="/suppliers-metal-trading/shipments/:supplierId" element={<SupplierShipmentsList/>}/>
    <Route path="/suppliers-metal-trading/shipments/:supplierId/view/:shptCid" element={<SupplierShipmentsView/>}/>
    <Route path="/suppliers-metal-trading/shipments/:supplierId/new" element={<SupplierShipmentsAddNew/>}/>
    <Route path="/suppliers-metal-trading/shipments/:supplierId/edit/:shptCid" element={<SupplierShipmentsEdit/>}/>
    <Route path="/suppliers-metal-trading/shipments/:supplierId/delete/:shptCid" element={<SupplierShipmentsDelete/>}/>
    <Route path="/suppliers-metal-trading/shipments/:supplierId/:pageNo" element={<SupplierShipmentsList/>}/>
    <Route path="/suppliers-ipr/profile/:supplierId" element={<SupplierProfileIpr/>}/>

    <Route path="/customers-metal-trading/profile/:customerId" element={<CustomerProfileMetalTrading/>}/>
    <Route path="/customers-metal-trading/shipments/:customerId" element={<CustomerShipmentsList/>}/>
    <Route path="/customers-metal-trading/shipments/:customerId/:pageNo" element={<CustomerShipmentsList/>}/>
    <Route path="/customers-ipr/profile/:customerId" element={<CustomerProfileIpr/>}/>
    <Route path="/customers-prn/profile/:customerId" element={<CustomerProfilePrn/>}/>

    {/* 404 Page */}
    <Route path="*" element={<NotFound/>} />
  </Routes>

);
export default SiteRoutes;