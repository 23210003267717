import React, { Component } from 'react';
import { Navigate, Link } from 'react-router-dom';
import '../../styles/SystemStyles.css';
import { PostData } from '../../datalink/PostData';
// import UserFeed from "../../UserFeed";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from '../NavBar';
import SysHeaderbar from '../SysHeaderbar';
import PageFooter from '../PageFooter';
import PageBanner from '../PageBanner';
import SysDemoContents from '../SysDemoContents';

// toast.configure();
class SupplierShipmentsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data:[],
            userFeed: '',
            redirectToReferrer: false
        };

        this.onChange = this.onChange.bind(this);
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#fff";
        if(sessionStorage.getItem("userData")){
            if(JSON.parse(sessionStorage.getItem("userData")).userType==="OR"){
                this.setState({redirectToReferrer: true});
            }
        }else{
            this.setState({redirectToReferrer: true});
        }
        window.scrollTo(0, 0);
    }

    onChange(e){
        this.setState({userFeed:e.target.value});
    }
    logout(){
        sessionStorage.setItem("userData",'');
        sessionStorage.clear();
        this.setState({redirectToReferrer: true});
    }

    render() {
        if (this.state.redirectToReferrer) {
            return (<Navigate to={'/'}/>);
        }

        if (sessionStorage.getItem("userData")===null || sessionStorage.getItem("userData")==="") {
            return (<Navigate to={'/'}/>);
        }
        
        const pageType = "system";
        let sessionvars="";
        const userName = JSON.parse(sessionStorage.getItem("userData")).userName;
        if(userName.toUpperCase()==="DEMOLOGIN"){
            sessionvars = {
                isDemo:true,
                demoComponents:"demoSupplierShipmentsList",
                loggedUserName:JSON.parse(sessionStorage.getItem("userData")).userName,
                loggedName:JSON.parse(sessionStorage.getItem("userData")).fullName,
                loggedUserType:JSON.parse(sessionStorage.getItem("userData")).userType,
                loggedUserDesignation:JSON.parse(sessionStorage.getItem("userData")).userDesignation
            }
        }else{
            sessionvars = {
                isDemo:false,
                loggedUserCid:JSON.parse(sessionStorage.getItem("userData")).cid,
                loggedUserEmail:JSON.parse(sessionStorage.getItem("userData")).emailAddress,
                loggedUserName:JSON.parse(sessionStorage.getItem("userData")).userName,
                loggedName:JSON.parse(sessionStorage.getItem("userData")).fullName,
                loggedUserType:JSON.parse(sessionStorage.getItem("userData")).userType,
                loggedUserDesignation:JSON.parse(sessionStorage.getItem("userData")).userDesignation
            }
        }

        return (
            <>
            <title>Supplier Shipments List - ERP | Britannia Beverages</title>
            <meta name="description" content="Supplier Shipments List - ERP | Britannia Beverages" />
            <NavBar pageType={pageType}/>
            
            <div className="main-content">
                <SysHeaderbar sessionvars={sessionvars}/>
                <main>
                    <h2 className="dash-title">Supplier Shipments List</h2>
                    <div style={{marginBottom:"10px", color:"#000"}}><Link to='/suppliers-metal-trading/shipments/1/new'>Add New Shipment</Link></div>
                    <PopulateThisPage sessionvars={sessionvars} />
                </main>
                <PageFooter pageType={pageType}/>
            </div>
            </>
        );
    }
}

class PopulateThisPage extends Component {
    render() {
        if(this.props.sessionvars.isDemo){
            return (<SysDemoContents {...this.props.sessionvars}/>);
        }else{
            return (
                <div>
                    contents here
                </div>
            )
        }
    }
}

export default SupplierShipmentsList;