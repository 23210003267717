import * as React from "react";
import { PostData } from '../datalink/PostData';

function getMonthName(monthNumber, format) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    const formatter = new Intl.DateTimeFormat('en-us', {month: format});

    return formatter.format(date);
}

export default class Clock extends React.Component {
  clockInterval = "";
  constructor(props) {
    super(props);
    this.handleDate = this.handleDate.bind(this);
    this.state = {
      servererror: "F",
      fulldate: "",
      hours: "",
      minutes: "",
      seconds: "",
      ampm: ""
    };
  }

  componentDidMount() {
    this.clockInterval = setInterval(this.handleDate, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.clockInterval);
  }

  render() {
    const { servererror, fulldate, hours, minutes, seconds, ampm } = this.state;
    const secondsStyle = {
      transform: `rotate(${seconds * 6}deg)`
    };
    const minutesStyle = {
      transform: `rotate(${minutes * 6}deg)`
    };
    const hoursStyle = {
      transform: `rotate(${hours * 30}deg)`
    };
    const { title } = this.props;
    return (
      <div className={"clock"}>
        <h3 style={{fontWeight:"normal"}}>{title}</h3>
        <div className={"analog-clock"}>
          <div className={"dial seconds"} style={secondsStyle} />
          <div className={"dial minutes"} style={minutesStyle} />
          <div className={"dial hours"} style={hoursStyle} />
        </div>
        <div className={"digital-clock"}>
            <small>{fulldate}</small><br/>{hours}:{minutes} {ampm}
        </div>
      </div>
    );
  }

  handleDate() {
    let servererror = "F";

    const { city } = this.props;

    PostData(city,this.state,'worldclock').then((result) => {
        let receivedServerResponse = result.serverRes;

        if(receivedServerResponse.error === "T"){ // Check if "error" is true
          servererror = "T";
          // this.setState({ servererror, fulldate, hours, minutes, seconds, ampm });
        } else {
            let plainReceivedServerResponse = JSON.parse(JSON.stringify(receivedServerResponse));

            let fulldatetime = plainReceivedServerResponse.fulldatetime;
           
            let fulldate = getMonthName(Number(fulldatetime.substr(5,2)),"long") + " " + fulldatetime.substr(8,2) + ", " + fulldatetime.substr(0,4);

            let timeoftheday = "";
            let hours;
            let ampm;

            if(Number(fulldatetime.substr(11,2))>12){
                hours = Number(fulldatetime.substr(11,2))-12;
                ampm = " pm";
                timeoftheday = "pm";
            }else{
                hours = Number(fulldatetime.substr(11,2));
                if (hours === 0){
                  hours = 12;
                }
                ampm = " am";
                timeoftheday = "am";
            }
            
            let minutes = fulldatetime.substr(14,2);
            let seconds = fulldatetime.substr(17,2)
            servererror = "F";
            // if(Number(hours) === 12 && timeoftheday === "am" && (Number(minutes) > 0 || Number(seconds) > 0)) {
              // ampm = " pm";
              // timeoftheday = "pm";
            // }
            this.setState({ servererror, fulldate, hours, minutes, seconds, ampm });
        }
    });

    
  }

  formatTime(time) {
    return time < 10 ? `0${time}` : time;
  }
}