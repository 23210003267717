import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import './PageFooter.css';
// import copyrighttext from 'https://www.waterstationph.com/textfiles/copyrightSource.txt';

const CopyrightLine = () => {
    if(new Date().getFullYear()===2023){ // if current year is 2023
        return(<>&copy; 2023 Britannia Beverages</>);
    } else { // This will be displayed after 2022
        return (<>&copy; 2023 - {new Date().getFullYear()} Britannia Beverages | All Rights Reserved</>);
    }
};

function GenericFooter(props) {
    return (
        <section className="huauNA">
            <div className="sectionBlock">
                <div className="footer-bottom">
                    
                    <p className="copyright-text"><CopyrightLine/></p>
                    {/* <p className="copyright-text">&copy; {copyrightYear} Britannia Beverages | All Right Reserved</p> */}
                </div>
            </div>
        </section>
    );
}

function SystemFooter(props) {
    return (
        <div className="footersys">
            <ul>
                <li><CopyrightLine/></li>
            </ul>
        </div>
    );
}

function PageFooter(props) {
    const pageType = props.pageType;
    if (pageType === "generic") {
        return <GenericFooter />;
    }else{
        return <SystemFooter />;
    }
}
  
export default PageFooter;